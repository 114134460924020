import {createI18n} from "vue-i18n";

import fr from './fr.json'

export const defaultLocale = 'fr'
export const languages = {
    fr
}


const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages: Object.assign(languages)
})

export const primeVueLocales = {
    clear: "Vider",
    apply: "Appliquer",
    accept: "Oui",
    reject: "Non",
    cancel: "Annuler",
    emptyFilterMessage: "Aucun résultat",
    emptyMessage: "Aucune option disponible"
}

export default i18n


