import * as validators from '@vuelidate/validators'
import i18n from '@/locales'

const { createI18nMessage } = validators
const { t } = i18n.global || i18n
const withI18nMessage = createI18nMessage({t})

export const required = withI18nMessage(validators.required)

export const minValue = withI18nMessage(validators.minValue, {withArguments: true})

export const numeric = withI18nMessage(validators.numeric)