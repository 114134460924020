<template>

    <i v-bind="$attrs" v-tooltip="tooltipMessage"
       class="icon pi pi-info-circle align-baseline mx-1 inline"
       @click="openHelp" :class="{popupable:popup}"

    ></i>

</template>

<script>
export default {
    name: "HelperIcon",
    props: {
        message: String,
        tooltip: String,
        popup: Boolean,
        popupContent: String,
        popupTitle: String,
        popupPosition : String
    },

    computed: {
        tooltipMessage() {
            if (this.tooltip)
                return {
                    value: this.$t(this.tooltip) ,
                }
            else
                return {
                    disabled: true
                }
        }
    },

    methods: {
        openHelp() {
            if (this.popup){
                this.$help.open({
                    title: this.popupTitle,
                    content: this.popupContent,
                    position: this.popupPosition,
                })
            }
        }
    }


}
</script>

<style scoped>
.icon {
    @apply text-lg font-thin transition-all;

    &:hover {
        @apply text-blue-500 cursor-help;
    }

    &.popupable:hover{
        @apply cursor-pointer;
    }
}

</style>